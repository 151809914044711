import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import Cadastro from '../interface/modais/modal_cadastro'
import Header from '../interface/header'
import { GoogleLogin } from '@react-oauth/google'
import { Box, Button, CircularProgress, Container, Divider, TextField, Typography } from '@mui/material'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import { isBrowser, isMobile } from 'react-device-detect'

const InputTexto = styled(TextField)(({ error }) => {
    const theme = useTheme()
    return {
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#FAE3D4',
            fontSize: '14px',
            '& fieldset': {
                borderWidth: '2px',
                borderColor: error ? theme.palette.error.main : '#bfbfbf',
            },
            '&:hover fieldset': {
                borderColor: '#bfbfbf',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#bfbfbf',
            },
        },
        '& .MuiInputBase-input': {
            padding: '14px 18px',
            fontSize: '1rem',
        },
        '& .MuiInputBase-input:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #FAE3D4 inset',
            '-webkit-text-fill-color': '#482B26',
        },
    }
})

const Homepage = () => {
    const [modalCadastro, setModalCadastro] = useState(false)
    const [logando, setLogando] = useState(false)
    const [username, setUsername] = useState('')
    const [senha, setSenha] = useState('')
    const [error, setError] = useState(false)
    const navigate = useNavigate()
    const theme = useTheme()
    
    const login = async (username, senha) => {
        try {
            setLogando(true)
            const response = await fetch('https://transcritor-ia.com:8086/api/auth/login', {
                method: 'POST',
                headers: { 'accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password: senha }),
            })
            setLogando(false)
            if (response.ok) {
                const resposta = await response.json()
                localStorage.setItem('key', resposta.token)
                localStorage.setItem('perm', resposta.plano)
                localStorage.setItem('user', JSON.stringify({ name: resposta.username }))
                navigate('/coleções')
            } else {
                setError(true)
            }
        } catch (error) {
            setLogando(false)
            console.error('Erro no login:', error)
            alert('Não foi possível conectar ao servidor. Tente novamente mais tarde.')
        }
    }    

    const loginGoogle = async (googleData) => {
        try {
            const dados = jwtDecode(googleData.credential)
            const response = await fetch('https://transcritor-ia.com:8086/api/auth/google/',{
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sub: parseInt(dados.sub),
                    name: dados.name,
                    email: dados.email
                })
            })

            if(response.ok){
                const resposta = await response.json()
                localStorage.setItem('key', resposta.token)
                localStorage.setItem('user', JSON.stringify(dados))
                localStorage.setItem('perm', resposta.plano)
                navigate('/coleções')
            } else{
                console.log(response)
                console.log(JSON.stringify({
                    sub: parseInt(dados.sub),
                    name: dados.name,
                    email: dados.email
                }))
            }
        } catch (error) {
            console.error("Falha no login google")
        }
    }

    useEffect(() => {
        const videoElement = document.getElementById("vid")
        try {
            videoElement.play()
        } catch (error) {
            console.log("O autoplay falhou.")
        }
    }, [])
      
    return (
        <Container disableGutters maxWidth={false} className={`relative flex flex-col h-[100vh] overflow-hidden`} style={{ backgroundColor: theme.palette.custom.cor_fundo }}>
            <div className={`absolute end-80  flex ${isBrowser ? 'items-center' : 'items-start'} justify-end w-full h-screen `}>
                <img alt='imagem_fundo_1' src='./imagens/2.jpg' className={`absolute mt-6  ${isBrowser ? 'h-[60vh] mr-52' : 'h-[80vh] mr-[200px]'} -rotate-12`}></img>
                <img alt='imagem_fundo_2' src='./imagens/3.jpg' className={`absolute ${isBrowser ? 'h-[60vh]' : 'h-[80vh]'} rotate-45`}></img>
                <img alt='imagem_fundo_3' src='./imagens/4.png' className={`absolute  ${isBrowser ? 'h-[60vh] -mr-52' : 'h-[80vh] -mr-[600px]'} rotate-12`}></img>
            </div>
            {modalCadastro &&
               <Cadastro 
                setModalCadastro={() => setModalCadastro(false)}
                funcLogin={login}
               /> 
            }
            
            <Header/>

            <Box className={`flex ${isBrowser ? 'items-center gap-40 justify-center' : 'items-center gap-5 mt-5'}  h-full`} sx={{ flexDirection: isBrowser ? 'row' : 'column-reverse' }}>
                <Box className={`${isBrowser ? 'w-[55%]' : 'w-[95%]'} z-30 pb-5`}>

                    <Box px={5}>
                        <Box className='flex items-center w-[100%] rounded-lg'>
                            <video id="vid" style={{ width: '100%', maxHeight: '500px', display: 'block', borderRadius: 'inherit' }} loop muted>
                                <source src="./imagens/IMG_0852.MP4" type="video/mp4" />
                                Seu navegador não suporta de vídeos.
                            </video>
                        </Box>
                    </Box>
                </Box>
            
                <Box className={`relative flex flex-col items-center justify-center p-3 rounded-xl shadow-2xl z-30 h-[400px] w-[350px]`} style={{boxShadow: '5px 8px 10px rgba(0, 0, 0, 0.7)', backgroundColor: "var(--marrom-popup-transp)" }}>
                    <Typography mb={1} sx={{mt: '-30px', fontSize: '30px', fontWeight: 600, color: "#482B26", width: '100%', textAlign: 'center' }}>Login</Typography>
                    <form 
                        className='w-full '
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                login(username, senha)
                            }
                        }}
                    >    
                        <InputTexto 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            fullWidth 
                            margin='dense' 
                            size='small' 
                            label='Login' 
                            variant='outlined' 
                            name="username" 
                            type='text' 
                            error={error} 
                            theme={theme}
                        />
                        <InputTexto 
                            value={senha} 
                            onChange={(e) => setSenha(e.target.value)} 
                            fullWidth  
                            margin='dense' 
                            size='small' 
                            label='Senha' 
                            variant='outlined' 
                            name="password" 
                            type='password' 
                            error={error} 
                            theme={theme}
                        />
                        
                        {logando ? (
                            <Button variant='contained' fullWidth sx={{ padding: 1, mt: 1, bgcolor: "#482B26" }}> 
                                <CircularProgress size={30} color="inherit"/> 
                            </Button>      
                        ) : ( 
                            <Button variant='contained' onClick={() => login(username, senha)} fullWidth sx={{ padding: 1, fontSize: '15px',  fontWeight: 600, mt: 1, bgcolor: "#482B26" }}>
                                Entrar
                            </Button>
                        )}
                    </form>

                    <div className='w-full text-center my-2'>
                        <span onClick={() => setModalCadastro(true)} className='text-md font-semibold cursor-pointer'>
                            Criar conta
                        </span>
                    </div>

                    <Divider sx={{width: '100%'}} />

                    <Box className='w-full mt-5 -mb-5'>
                        <Box className='w-full flex justify-center'>
                            <GoogleLogin
                                onSuccess={credentialResponse => loginGoogle(credentialResponse)}
                                onError={() => alert('Erro ao autenticar com Google.')}
                                width={isMobile ? 600 : 250}
                                shape="pill"
                                theme="outline"
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default Homepage