import React, { useEffect, useState } from 'react'
import { Box, TextField, InputAdornment, IconButton, Typography, CircularProgress } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Header from '../interface/header'
import MenuT from '../interface/menuT'

const BuscaPage = () => {
    const [resultados, setResultados] = useState([])
    const [resultadosTrechos, setResultadosTrechos] = useState([])
    const [carregando, setCarregando] = useState(false)
    const [textoBusca, setTextoBusca] = useState('')
    const [erro, setErro] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const token = localStorage.getItem('key')
    const params = new URLSearchParams(location.search)
    const searchQuery = params.get('search')
    const livroId = params.get('id')

    useEffect(() => {
        if (searchQuery) {
            realizarBusca(searchQuery, livroId)
        }
    }, [])

    useEffect(() => {
        if (searchQuery) {
            setTextoBusca(searchQuery)
        }
    }, [location.search])

    const realizarBusca = async (query, livroId = false) => {
        setCarregando(true)
        setErro('')
    
        const url = livroId 
            ? `https://transcritor-ia.com:8086/api/livro/pesquisar/?trecho=${query}&id_livro=${livroId}`
            : `https://transcritor-ia.com:8086/api/livro/pesquisar/?trecho=${query}`
    
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            })
    
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
    
            const text = await response.text()
    
            if (!text) {
                throw new Error('Empty response')
            }
    
            const data = JSON.parse(text)
            const fetchLivroPromises = data.map(async trecho => {
                const livroResponse = await fetch(`https://transcritor-ia.com:8086/api/livro/${trecho.id_livro}/`, {
                    method: 'GET',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Token ${token}`,
                    }
                })
    
                const livroData = await livroResponse.json()
    
                const imagensResponse = await fetch(`https://transcritor-ia.com:8086/api/livro/${trecho.id_livro}/imagens/`, {
                    method: 'GET',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Token ${token}`,
                    }
                })
    
                const imagensData = await imagensResponse.json()
                const imagem = imagensData.find(img => img.id_imagem === trecho.id_imagem)
                return { ...trecho, livroData, quantidadeImagens: imagensData.length, numeroImagem: imagem ? imagem.numero : null }
            })
    
            const resultadosComLivros = await Promise.all(fetchLivroPromises)
            setResultadosTrechos(resultadosComLivros)
        } catch (error) {
            if (error.message === 'Empty response') {
                realizarBusca(searchQuery, livroId)
            } else {
                setErro('Erro ao buscar trechos de livros. Por favor, tente novamente.')
            }
            setResultadosTrechos([])
        } finally {
            setCarregando(false)
        }
    }

    const formatarTrecho = (trecho, intervalo) => {
        const [inicio, fim] = intervalo
        const parte1 = trecho.slice(0, inicio)
        const parte2 = trecho.slice(inicio, fim)
        const parte3 = trecho.slice(fim)
    
        return (
            <>
                {parte1}
                <strong>{parte2}</strong>
                {parte3}
            </>
        )
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            realizarBusca(textoBusca, livroId)
        }
    }

    return (
        <div className='flex flex-col h-[100vh]'>
            <Header perfil={true} />
            <MenuT
                rotas={[{ nome: 'Coleções', link: '/coleções' }, { nome: 'Busca' }]}
                botoes={[]}
            />
            <Box sx={{ padding: 2 }}>
                <TextField
                    fullWidth
                    placeholder="Digite sua busca"
                    value={textoBusca}
                    onChange={(e) => setTextoBusca(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: 1,
                        marginBottom: 2,
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none'
                            },
                        },
                        '& .MuiInputBase-root': {
                            borderBottom: '2px solid black',
                            borderRadius: 0
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => { navigate(-1); setTextoBusca('') }}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={{ flex: 1, overflowY: 'auto', paddingX: 2 }}>
                    {carregando ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : erro ? (
                        <Typography color="error">{erro}</Typography>
                    ) : (
                        <>
                            {resultadosTrechos.length > 0 && (
                                resultadosTrechos.map((resultado) => (
                                    <Box key={resultado.id_linha} sx={{ marginBottom: 2, borderBottom: '1px solid #ddd', paddingBottom: 1 }}>
                                        <Link 
                                            to={`/coleções/livro/${resultado.id_livro}/${resultado.livroData.nome}/imagem/${resultado.numeroImagem}/${resultado.quantidadeImagens}/${resultado.id_imagem}?linha=${resultado.id_linha}`}
                                            state={{ search: textoBusca }}
                                            className="custom-link"
                                        >
                                            {resultado.livroData.nome}
                                        </Link>
                                        <Typography variant="body2" color="textSecondary">
                                            "{formatarTrecho(resultado.trecho, resultado.intervalo_trecho)}"
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">Autor: {resultado.escrevente || 'Desconhecido'}</Typography>
                                    </Box>
                                ))
                            )}
                            {resultados.length > 0 && (
                                resultados.map((resultado) => (
                                    <Box key={resultado.id_livro} sx={{ marginBottom: 2, borderBottom: '1px solid #ddd', paddingBottom: 1 }}>
                                        <Typography variant="h6" component={Link} to={`/coleções/livro/${resultado.id_livro}/${resultado.nome}`}>
                                            {resultado.nome}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">Autor: {resultado.escrevente || 'Desconhecido'}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {resultado.local} | {resultado.periodo_inicial} - {resultado.periodo_final} | {resultado.tipo_documento}
                                        </Typography>
                                    </Box>
                                ))
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </div>
    )
}

export default BuscaPage