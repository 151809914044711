export default class Linha {
    constructor(pontos, transcricao = '', focado = false, id_linha, id_regiao, numero, certificada, linha, processando) {
        this.pontos = pontos
        this.transcricao = transcricao
        this.focado = focado
        this.id_linha = id_linha
        this.id_regiao = id_regiao
        this.numero = numero
        this.certificada = certificada
        this.linha = linha
        this.processando = false
    }

    setFoco(isFocado) {
        this.focado = isFocado
    }

    atualizarPontos(novosPontos, areas = []) {
        for (const area of areas) {
            if (area.contemLinha(novosPontos)) {
                this.pontos = novosPontos
                return true
            }
        }
        return false
    }

    detectarProximidade(x, y, tolerancia = 10) {
        const [x1, y1, x2, y2] = this.pontos
        const distToStart = Math.hypot(x - x1, y - y1)
        const distToEnd = Math.hypot(x - x2, y - y2)
        if (distToStart < tolerancia) return 0
        if (distToEnd < tolerancia) return 2
        return null
    }

    static handleDesenhoStart(pos, transformCoords, setLinhaAtual) {
        const { x, y } = transformCoords(pos.x, pos.y)
        setLinhaAtual([Math.round(x), Math.round(y), Math.round(x), Math.round(y)])
    }

    static handleDesenho(pos, transformCoords, linhaAtual, setLinhaAtual) {
        if (linhaAtual.length === 0) return

        const { x, y } = transformCoords(pos.x, pos.y)
        setLinhaAtual([linhaAtual[0], linhaAtual[1], Math.round(x), Math.round(y)])
    }

    static async handleDesenhoFim(linhaAtual, linhas, regioes, setLinhas, enviarLinhaParaAPI, setLinhaAtual) {
        if(linhaAtual[0] !== linhaAtual[2] && linhaAtual[1] !== linhaAtual[3]) {
            for (const regiao of regioes) {
                const dentroDaRegiao = 
                    regiao.verificarPontos(linhaAtual[0], linhaAtual[1]) &&
                    regiao.verificarPontos(linhaAtual[2], linhaAtual[3])
        
                if (dentroDaRegiao) {
                    let numero
        
                    for (const linha of linhas.filter((linha) => regiao.contemLinha(linha.pontos))) {
                        if (linha.pontos[1] <= linhaAtual[1] && linha.pontos[3] <= linhaAtual[3]) {
                            numero = linha.numero + 1
                        }
                    }
        
                    const novaLinha = new Linha(
                        [linhaAtual[0], linhaAtual[1], linhaAtual[2], linhaAtual[3]].map(Math.round),
                        "",
                        false,
                        linhas.length + 1,
                        regiao.id_regiao,
                        numero || 1,
                        false
                    )
        
                    setLinhas((prevLinhas) => [...prevLinhas, novaLinha])
                    
                    // faz isso aqui porque as vezes o processo de enviarLinhaParaAPI pode demorar um pouco
                    // ai a linha ficava presa no mouse enquanto esperava a resposta da api
                    // e só solvata quando a resposta chegava
                    const tempLinhaAtual = [
                        [Math.round(linhaAtual[0]), Math.round(linhaAtual[1])],
                        [Math.round(linhaAtual[2]), Math.round(linhaAtual[3])]
                    ]
                    setLinhaAtual([])
        
                    const result = await enviarLinhaParaAPI({
                        id_regiao: regiao.id_regiao,
                        transcricao: "",
                        pontos: tempLinhaAtual,
                        numero: numero || 1
                    })
        
                    if (!result) {
                        setLinhas((prevLinhas) => prevLinhas.filter((linha) => linha !== novaLinha))
                    }
        
                    break
                } else {
                    setLinhaAtual([])
                }
            }
        } else {
            setLinhaAtual([])
        }
    }
}