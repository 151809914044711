import React, { useEffect, useState } from 'react'
import Header from '../interface/header'
import MenuT from '../interface/menuT'
import Livro from '../interface/livro'
import Wizzard from '../interface/modais/modal_wizard_livro'
import Busca from '../interface/busca'
import { LuFileInput } from 'react-icons/lu'
import { CircularProgress, useTheme } from '@mui/material'
import { TbPackageImport } from 'react-icons/tb'
import ImportModal from '../interface/modais/modal_importar'
import { isBrowser } from 'react-device-detect'

const Colecoes = () => {
    const token = localStorage.getItem('key')
    const theme = useTheme()
    const [nivelPerm, setNivelPerm] = useState()
    const [livros, setLivros] = useState([])
    const [livrosFiltrados, setLivrosFiltrados] = useState([])
    const [modalWizzard, setModalWizzard] = useState(false)
    const [loading, setLoading] = useState(true)
    const [modalImport, setModalImport] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [carregandoImportacao, setCarregandoImportacao] = useState(false)

    const getLivros = async () => {
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/livro/', {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })

            if (response.ok) {
                const resposta = await response.json()
                setLivros(resposta)
                setLivrosFiltrados(resposta)
                await getLivrosCompartilhados()
            }
        } catch (error) {
            console.log('erro ao tentar obter livros', error)
        } finally {
            setLoading(false)
        }
    }

    const getLivrosCompartilhados = async () => {
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/livro/compartilhados/', {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })

            if (response.ok) {
                const resposta = await response.json()
                const livrosCompartilhadosDetalhados = await Promise.all(
                    resposta.map(async (livroCompartilhado) => {
                        const livroResponse = await fetch(`https://transcritor-ia.com:8086/api/livro/${livroCompartilhado.id_livro}/`, {
                            method: 'GET',
                            headers: {
                                'accept': 'application/json',
                                'Authorization': `Token ${token}`
                            }
                        })
                        if (livroResponse.ok) {
                            const livroDetalhes = await livroResponse.json()
                            return { ...livroDetalhes, livroCompartilhado: true, dono: livroCompartilhado.usuario.email, funcao: livroCompartilhado.funcao}
                        }
                        return null
                    })
                )

                const livrosValidos = livrosCompartilhadosDetalhados.filter(livro => livro !== null)
                setLivros(prevLivros => [...prevLivros, ...livrosValidos])
                setLivrosFiltrados(prevLivros => [...prevLivros, ...livrosValidos])
            }
        } catch (error) {
            console.log('erro ao tentar obter livros compartilhados', error)
        }
    }

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0])
    }

    const handleImport = async () => {
        if (!selectedFile) {
            alert('Por favor, selecione um arquivo para importar.')
            return
        }
    
        const formData = new FormData()
        formData.append('arquivo_zip', selectedFile)
    
        try {
            setCarregandoImportacao(true)
            const response = await fetch('https://transcritor-ia.com:8086/api/livro/importar-livro/', {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`
                },
                body: formData
            })
    
            if (response.ok) {
                // atraso para garantir que o servidor processou a importação
                await new Promise(resolve => setTimeout(resolve, 2000))
                await getLivros()
                setModalImport(false)
            } else {
                alert('Erro ao importar o livro.')
            }
        } catch (error) {
            console.log('Erro ao importar livro:', error)
        } finally {
            setCarregandoImportacao(false)
        }
    }

    useEffect(() => {
        getLivros()
        setNivelPerm(localStorage.getItem('perm'))
        // eslint-disable-next-line
    }, [])

    return (
        <div id="modal-root" className='flex flex-col h-[100vh]'>
            {modalWizzard &&
                <Wizzard 
                    setModalWizzard={() => (setModalWizzard())}
                    getLivros={() => getLivros()}
                />
            }
            <ImportModal
                aberto={modalImport}
                aoFechar={() => setModalImport(false)}
                aoMudarArquivo={handleFileChange}
                aoImportar={handleImport}
                carregandoImportacao={carregandoImportacao}
            />
            <Header perfil={true} />
            <MenuT
                rotas={[{ nome: 'Coleções' }]}
                botoes={[
                    <div className='flex items-center gap-5'>
                        <Busca placeholder="Pesquise nas suas transcrições" />
                        <div className='flex gap-5 -mr-7'>
                            {nivelPerm !== 'colaborador' &&
                                <button onClick={() => (setModalWizzard(true))} title='Novo livro' className='botao_3 flex items-center justify-center'>
                                    <LuFileInput color={theme.palette.custom.cor_texto} size={isBrowser ? 15 : 40} />
                                </button>
                            }
                            {nivelPerm !== 'colaborador' &&
                                <button onClick={() => setModalImport(true)} label='Importar livro formato Transkribus' title='Importar livro formato Transkribus' className='botao_3 flex items-center justify-center'>
                                    <TbPackageImport color={theme.palette.custom.cor_texto} size={isBrowser ? 15 : 40} />
                                </button>
                            }
                        </div>
                    </div>
                ]}
            />
            <div className='w-full px-2 -pt-4'>
                <div className='flex w-full justify-end font-bold text-xl'>
                    
                </div>
                {loading ? 
                    <div className='flex justify-center items-center h-[50vh]'>
                        <div><CircularProgress size={30} color="inherit"/></div>
                    </div>
                    :
                    livros.length > 0 ? 
                        <div className='grid_livro flex mx-5 justify-start items-center'>
                            {livrosFiltrados.sort((a, b) => a.id_livro - b.id_livro).map(livro => (
                                <Livro
                                    key={livro.id_livro}
                                    id={livro.id_livro}
                                    funcao={livro.funcao}
                                    livroCompartilhado={livro.livroCompartilhado}
                                    dono={livro.dono}
                                    nome={livro.nome}
                                    local={livro.local}
                                    periodoInicio={livro.periodo_inicial}
                                    periodoFim={livro.periodo_final}
                                    escrivao={livro.escrevente}
                                    transcricao_publica={livro.transcricao_publica}
                                    capa={livro.capa}
                                    getLivros={() => getLivros()}
                                />
                            ))}                
                        </div> 
                        : 
                        nivelPerm === 'colaborador' ? 
                            <div className='flex justify-center text-center items-center h-[50vh]'>Não encontramos nenhum livro disponível.<br/>Faça um upgrade para o plano Associado ou solicite que um associado compartilhe um livro com você.</div>
                        :
                            <div className='flex justify-center items-center h-[50vh]'>Você ainda não criou nenhum livro.</div>
                }
            </div>
        </div>
    )
}

export default Colecoes