import React from 'react'
import PerfilMembro from '../interface/perfilMembro'
import { Box, Typography, Divider, useTheme, Grid2 } from '@mui/material'
import Botao from '../interface/botão'
import { RiMailOpenFill } from "react-icons/ri"
import { useNavigate } from 'react-router-dom'
import { FaWhatsapp } from 'react-icons/fa'
import { isBrowser } from 'react-device-detect'
import { IoIosArrowBack } from 'react-icons/io'

const Equipe = () => {
    const navigate = useNavigate()
    const theme = useTheme()

    const formatarTermosEmIngles = (texto) => {
        const termosEmIngles = ['Developer', 'Client', 'side', 'Frontend', 'Produc', 'Owner', 'Server', 'Backend', 'UI', 'UX', 'Designer']
        const partes = texto.split(/\b/).map((palavra, index) => 
            termosEmIngles.includes(palavra) ? <b key={index}><i>{palavra}</i></b> : palavra
        )
        return <>{partes}</>
    }    

    const membros = [
        {
            nome: 'Raimundo Valter',
            funcao: formatarTermosEmIngles('Coordenador de Inovação'),
            descricao: 'Doutor em Inteligência Artificial, dedica-se a promover um Brasil mais tecnológico e produtivo através de inovações.',
            redesSociais: {
                instagram: 'https://www.instagram.com/profraimundovalter?igsh=dzV0ZnRtbHZ1ZDVo&utm_source=qr',
                linkedin: 'https://www.linkedin.com/in/raimundo-valter-undefined-15868b288/',
                github: 'https://github.com/DragaoLab',
            },
            imagem: './imagens/image_14.png',
        },
        {
            nome: 'George Ney Almeida',
            funcao: formatarTermosEmIngles('Produc Owner'),
            descricao: 'Mestre em Matemática e acredita que a verdadeira inovação é impulsionada pela força. Afinal,  “é o fraco que é cruel. A gentileza só pode vir do forte” (Leo Rosten).',
            redesSociais: {
                instagram: '',
                linkedin: '',
                github: null,
            },
            imagem: './imagens/image_8.png',
        },
        {
            nome: 'Cindy Vitoria',
            funcao: formatarTermosEmIngles('UI/UX Designer'),
            descricao: 'Estudante de Ciências da Computação, apaixonada por desenvolver interfaces digitais inovadoras que proporcionem experiências significativas.',
            tecnologias: ['./imagens/figma-logo.png'],
            redesSociais: {
                instagram: '',
                linkedin: '',
                github: '',
            },
            imagem: './imagens/image_17.png',
        },
        {
            nome: 'Rodrigo Cruz',
            funcao: formatarTermosEmIngles('Developer Server-side (Backend)'),
            descricao: 'Estudante de Ciências da Computação, com interesse em pesquisa e comprometido em desenvolver soluções de software para desafios do mundo real.',
            tecnologias: ['./imagens/python.png', './imagens/OpenCV_logo.webp'],
            redesSociais: {
                instagram: '',
                linkedin: '',
                github: 'https://github.com/rodrig-crzz',
            },
            imagem: './imagens/image_6.png',
        },
        {
            nome: 'Alexsandro Costa',
            funcao: formatarTermosEmIngles('Developer Server-side (Backend)'),
            descricao: 'Estudante de Ciências da Computação e desenvolvedor server-side, ciente de que, sem ele, a Ferrari não sai do lugar.',
            tecnologias: ['./imagens/python.png', './imagens/Django-Logo.png', './imagens/sql.png'],
            redesSociais: {
                instagram: '',
                linkedin: '',
                github: 'https://github.com/AlexsandroCosta',
            },
            imagem: './imagens/image_12.png',
        },
        {
            nome: 'Alysson Pinheiro',
            funcao: formatarTermosEmIngles('Developer Client-side (Frontend)'),
            descricao: 'Estudante de Ciências da Computação e desenvolvedor Front-End, apaixonado por criar interfaces intuitivas e experiências dinâmicas para a web.',
            tecnologias: ['./imagens/JavaScript-logo.png', './imagens/react-logo.png'],
            redesSociais: {
                instagram: 'https://www.instagram.com/alyssonhrn/',
                linkedin: 'https://www.linkedin.com/in/alyssonh/',
                github: 'https://github.com/AlyssonHenri',
            },
            imagem: './imagens/image_11.png',
        },
        {
            nome: 'Eduardo Mafezoli',
            funcao: formatarTermosEmIngles('Developer Server-side (Backend) | UI/UX Designer'),
            descricao: 'Estudante de Ciências da Computação, com experiência em desenvolvimento de produtos digitais e comprometido em transformar ideias inovadoras em soluções práticas.',
            tecnologias: ['./imagens/python.png', './imagens/Django-Logo.png', './imagens/sql.png', './imagens/figma-logo.png'],
            redesSociais: {
                instagram: '',
                linkedin: '',
                github: '',
            },
            imagem: './imagens/image_13.png',
        },
    ]

    return (
        <Box style={{ backgroundColor: theme.palette.custom.cor_fundo }}>
            <div style={{ backgroundColor: "white" }} className={`flex flex-row items-center justify-between w-full px-[85px] ${isBrowser ? 'h-[72px]' : 'h-32'}`}>
                <Box sx={{mt:1}}>
                    <Box>
                        <Typography  style={{ fontSize: '25px', fontWeight: 'bold', color: theme.palette.custom.cor_0, marginLeft: "20px", marginTop: "10px", fontFamily: '"Pinyon Script", serif', display: 'inline' }}>
                            Transcritor
                        </Typography>   
                        <Typography variant={isBrowser ? 'h5' : 'h2'} style={{ marginLeft: '-4px', color: theme.palette.custom.cor_0, marginLeft: "5px", marginTop: "10px", fontFamily: '"Anta", serif', display: 'inline' }}>
                            IA
                        </Typography>
                    </Box>
                </Box>
                <Botao variant="default" onClick={() => navigate(-1)}>
                    <IoIosArrowBack size={isBrowser ? 20 : 40}/> Voltar
                </Botao>    
            </div>
            <Box sx={{ mt: 2, mx: 5 }}>
                <Box>
                    <Typography
                        variant="h4"
                        style={{
                            color: theme.palette.custom.cor_0,
                            marginLeft: '20px',
                            marginTop: '10px',
                            display: 'inline',
                        }}
                    >
                        Equipe
                    </Typography>
                    <Typography
                        variant="h4"
                        style={{
                            color: theme.palette.custom.cor_0,
                            fontWeight: 'bold',
                            marginLeft: '5px',
                            marginTop: '10px',
                            display: 'inline',
                        }}
                    >   
                        Transcritor.IA
                    </Typography>
                </Box>
                <Divider sx={{ mr: 2, borderBottomWidth: '3px' }} />
                <Divider sx={{ ml: 2, mt: '4px', borderBottomWidth: '2px' }} />

                <div>
                    <Grid2 
                        container 
                        spacing={2} 
                        sx={{ 
                            mb: 2, 
                            minHeight: 'calc(100vh - 300px)',
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center' 
                        }}
                    >
                        {membros.map((membro, index) => (
                            <PerfilMembro {...membro} key={index} />
                        ))}
                    </Grid2>
                </div>
            </Box>
            <Box sx={{ color: theme.palette.custom.cor_2, backgroundColor: '#fafafa', display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 5, py: 5 }}>
                <Box>
                    <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 4 }}>
                        Contato
                    </Typography>
                    <Typography sx={{fontWeight: 'bold'}}>O professor George Almeida é o idealizador do Transcritor-ia.</Typography>
                    <Typography>Para mais informações, por gentileza, use os seguintes canais.</Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FaWhatsapp size={40}/>
                        <Typography sx={{ml: 1,fontWeight: 'bold'}}>+55 (88) 996603972</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <RiMailOpenFill size={40}/>
                        <Typography sx={{ml: 1,fontWeight: 'bold'}}>george.almeida@ifce.edu.br</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Equipe