import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#f44336',
        },
        custom: {
            cor_0: '#482B26',
            cor_1: '#e7e2e1',
            cor_2: '#876148',
            cor_3: '#958982',
            cor_4: '#f2d8c8',
            cor_5: '#5c4643',
            cor_menu_t: '#655a59',
            cor_fundo: '#f5f5f5',
            cor_texto: '#f2d8c8',
            cor_tolltip: '#181d28',
            certificada: '#e9ecd7',
            focada: '#88B2BD',
            padrao: '#B08354',
        },
    },
    typography: {
        fontFamily: '"Inter", serif',
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '.MuiInputLabel-outlined:has(+ .MuiOutlinedInput-root > .MuiOutlinedInput-input:-webkit-autofill)': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    },
                    '.MuiOutlinedInput-input:-webkit-autofill + .MuiOutlinedInput-notchedOutline > legend': {
                        maxWidth: '100%',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
})

export default theme