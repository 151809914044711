import React, { useEffect, useState } from 'react'
import { PiDot, PiTrash } from 'react-icons/pi'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../interface/header'
import MenuT from '../interface/menuT'
import Imagem from '../interface/imagem'
import Upload from '../interface/modais/modal_upload_pagina'
import Busca from '../interface/busca'
import { FiFileText } from 'react-icons/fi'
import { LuFileInput } from 'react-icons/lu'
import { LiaFileDownloadSolid } from 'react-icons/lia'
import { TbPackageExport } from "react-icons/tb"
import { Box, CircularProgress, Modal, Typography, useTheme } from '@mui/material'
import { DndContext, closestCenter, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { isBrowser } from 'react-device-detect'

const Imagens = () => {
    const token = localStorage.getItem('key')
    const theme = useTheme()
    const navigate = useNavigate()
    const { dono, id, nome } = useParams()
    const [paginas, setPaginas] = useState([])
    const [modalUpload, setModalUpload] = useState(false)
    const [idSelecao, setIdSelecao] = useState([])
    const [carregando, setCarregando] = useState(true)
    const [exportando, setExportando] = useState(false)
    const [exportandoZip, setExportandoZip] = useState(false)
    const [deletando, setDeletando] = useState(false)
    const [arquivosSelecionados, setArquivosSelecionados] = useState([])
    const [falhaExportar, setFalhaExportar] = useState(false)

    const getPaginas = async () => {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/imagens/`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })

        if (response.ok) {
            const resposta = await response.json()
            setPaginas(resposta)
            setCarregando(false)
        } else {
            navigate('/coleções')
        }
    }

    const deletarPaginas = async () => {
        setDeletando(true)
        for (const id_imagem of idSelecao) {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/imagens/${id_imagem}/`, {
                method: 'DELETE',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })

            if (!response.ok) {
                console.error(`Erro ao deletar a imagem ${id_imagem}`)
            }
        }
        setIdSelecao([]) // Limpa seleção
        await getPaginas() // Atualiza lista
        setDeletando(false)
    }

    const iniciarExportacao = async () => {
        // Se já estiver exportando, não inicia outra.
        if (exportando) return 
    
        setExportando(true) // Marca o estado como exportando imediatamente.
    
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/exportar/?formato=pdf`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`,
            },
        })
    
        if (response.ok) {
            verificarStatusExportacao() // Inicia a verificação do status.
        } else {
            setExportando(false) // Reseta o estado caso ocorra um erro.
            console.error('Erro ao iniciar a exportação')
        }
    }
    
    const verificarStatusExportacao = async () => {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`,
            },
        })

        if (response.ok) {
            const data = await response.json()
    
            if (data.status_pdf) {
    
                if (data.status_pdf.status === 'Concluído') {
                    setExportando(false) 
                    window.open('https://transcritor-ia.com:8086' + data.path_pdf, '_blank')
                } else if (data.status_pdf.status === 'Em andamento') {
                    // Continua verificando se a exportação está em progresso
                    setTimeout(verificarStatusExportacao, 1000)
                }
            } else {
                setExportando(false) // Reseta o estado se não houver exportação ativa
            }
        } else {
            setExportando(false) // Reseta o estado
            setFalhaExportar(true)
        }
    }

    const iniciarExportacaoZip = async () => {
        // Se já estiver exportando, não inicia outra.
        if (exportandoZip) return 
    
        setExportandoZip(true) // Marca o estado como exportando imediatamente.
    
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/exportar/?formato=zip`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`,
            },
        })
    
        if (response.ok) {
            verificarStatusExportacaoZip() // Inicia a verificação do status.
        } else {
            setExportandoZip(false) // Reseta o estado caso ocorra um erro.
            console.error('Erro ao iniciar a exportação')
        }
    }
    
    const verificarStatusExportacaoZip = async () => {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`,
            },
        })

        if (response.ok) {
            const data = await response.json()
    
            if (data.status_zip) {
    
                if (data.status_zip.status === 'Concluído') {
                    setExportandoZip(false) // Finaliza o estado de exportação
                    const link = document.createElement('a')
                    link.href = 'https://transcritor-ia.com:8086' + data.path_zip
                    link.download = 'livro.zip'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                } else if (data.status_zip.status === 'Em andamento') {
                    // Continua verificando se a exportação está em progresso
                    setTimeout(verificarStatusExportacaoZip, 1000)
                }
            } else {
                setExportandoZip(false) // Reseta o estado se não houver exportação ativa
            }
        } else {
            setExportandoZip(false) // Reseta o estado em caso de erro
            setFalhaExportar(true)
        }
    }

    const handleFileChange = (e) => {
        const arquivosSelecionados = Array.from(e.target.files)
        setArquivosSelecionados(arquivosSelecionados)
        setModalUpload(true)
    }

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
            distance: 5,
            filter: (event) => !event.target.closest('.MuiCheckbox-root')
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
            delay: 250,
            tolerance: 5,
            filter: (event) => !event.target.closest('.MuiCheckbox-root')
            }
        })
    )

    useEffect(() => {
        getPaginas()
        // eslint-disable-next-line
    }, [])
    
    const handleDragEnd = async (event) => {
        const { active, over } = event
    
        if (active.id && over.id) {
            if (active.id !== over.id) {
                const numeroAntes = paginas.findIndex((item) => item.id_imagem === active.id)
                const numeroDepois = paginas.findIndex((item) => item.id_imagem === over.id)
        
                // Atualiza localmente as páginas para refletir a nova ordem
                setPaginas((items) => arrayMove(items, numeroAntes, numeroDepois))
        
                try {
                    const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/imagens/${active.id}/mover/`, {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Authorization': `Token ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ novo_numero: numeroDepois + 1 }),
                    })
        
                    if (response.ok) {
                        await getPaginas() // Atualiza as páginas do servidor
                    } else {
                        console.error('Erro ao mover a imagem:', response.statusText)
                    }
                } catch (error) {
                    console.error('Erro na requisição:', error)
                }
            }
        }
    }    

    return (
        <div className='flex flex-col h-[100vh]'>
            {modalUpload &&
                <Upload
                    setModalUpload={() => setModalUpload(false)}
                    idLivro={id}
                    getPaginas={getPaginas}
                    arquivosSelecionados={arquivosSelecionados}
                />
            }
            <Header perfil={true} />
            <MenuT
                rotas={[
                    { nome: 'Coleções', link: '/coleções' },
                    { 
                        nome: (
                            <div className='flex gap-1'>
                                <div className='flex'>
                                    <FiFileText className={`mt-[6px]`} color='white' size={20} />
                                    <h1 className='mt-[2px] ml-1 '>Páginas de</h1>
                                </div>
                                <h1 className='mt-[2px] truncate'>{nome}</h1>
                            </div>
                        )
                    }
                ]}
                botoes={[
                    !dono && (
                        <div className={`flex justify-start items-center gap-5`}>
                            <div className='flex items-center gap-5'>
                                { isBrowser && <Busca placeholder="Pesquise neste livro" livro={id} /> }
                                <button onClick={() => document.getElementById('fileInput').click()} className='botao_3' title='Adicionar Páginas'>
                                    <LuFileInput color={theme.palette.custom.cor_texto} size={20} />
                                </button>
                                <input
                                    type="file"
                                    id="fileInput"
                                    multiple
                                    hidden
                                    onChange={handleFileChange}
                                />
                                <button onClick={iniciarExportacaoZip} className='botao_3' title='Exportar formato Transkribus'>
                                    <TbPackageExport color={theme.palette.custom.cor_texto} size={20} />
                                </button>
                                <button onClick={iniciarExportacao} className='botao_3' title='Baixar PDF'>
                                    <LiaFileDownloadSolid color={theme.palette.custom.cor_texto} size={20} />
                                </button>
                                <button onClick={deletarPaginas} className='botao_3' title='Deletar arquivos selecionados'>
                                    <PiTrash color={theme.palette.custom.cor_texto} size={20} />
                                </button>
                            </div>
                        </div>
                    )
                ]}
            />
            <div className='w-full pb-5'>
                {!carregando ? (
                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext items={paginas.map((item) => item.id_imagem)}>
                            <div className="grid_paginas flex mx-7 justify-start items-center">
                                {paginas.map((pagina) => (
                                    <Imagem
                                        key={pagina.id_imagem}
                                        id_imagem={pagina.id_imagem}
                                        nome={pagina.nome}
                                        numero={pagina.numero}
                                        miniatura={pagina.miniatura}
                                        setIdSelecao={setIdSelecao}
                                        idSelecao={idSelecao}
                                        total={paginas.length}
                                    />
                                ))}
                            </div>
                        </SortableContext>
                    </DndContext>
                ) : (
                    <div className='flex justify-center items-center h-[50vh]'>
                        <div><CircularProgress size={30} color="inherit" /></div>
                    </div>
                )}
            </div>
            <Modal open={exportando || exportandoZip}>
                <Box onClick={() => { setExportando(false); setExportandoZip(false); }} className='flex flex-col items-center justify-center h-full'>
                    <Box className='flex flex-col p-5 rounded justify-center items-center bg-white'>
                        <Typography variant="h6" sx={{ display: 'flex'}}>Estamos processando seu arquivo 
                            <span className='flex -mb-14 -mt-2'>
                                <PiDot size={60} className='animate-bounce -mr-10 -ml-5' />
                                <PiDot size={60} className='animate-bounce -mr-10' style={{ animationDelay: '0.2s' }} />
                                <PiDot size={60} className='animate-bounce -mr-4' style={{ animationDelay: '0.4s' }} />
                            </span>
                        </Typography>
                    </Box>
                </Box>
            </Modal>
            <Modal open={falhaExportar}>
                <Box onClick={() => { setFalhaExportar(false) }} className='flex flex-col items-center justify-center h-full'>
                    <Box className='flex flex-col p-5 rounded justify-center items-center bg-white'>
                        <Typography variant="h6" sx={{ display: 'flex'}}>Não foi possivel exportar seus arquivos, tente novamente mais tarde
                        </Typography>
                    </Box>
                </Box>
            </Modal>
            {deletando && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20'>
                    <CircularProgress size={50} color="inherit" />
                </div>
            )}
        </div>
    )
}

export default Imagens