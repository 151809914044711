import React from 'react'
import { Box, Typography } from '@mui/material'
import { IoLogoInstagram } from 'react-icons/io5'
import { FiGithub, FiLinkedin } from 'react-icons/fi'
import { isMobile } from 'react-device-detect'

const PerfilMembro = ({ nome, funcao, descricao, tecnologias, redesSociais, imagem }) => {

    return (
        <Box
            sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Box>
                <img
                    src={imagem}
                    alt={`foto_${nome}`}
                    style={{
                        height: '220px' ,
                        width: isMobile? '370px' :'240px',
                        objectFit: 'cover',
                    }}
                />
            </Box>
            <Box
                sx={{
                    backgroundColor: 'white',
                    height: isMobile ? tecnologias? '400px' : '300px' : '300px',
                    width: isMobile ? '100%' : '400px',
                    borderRadius: '25px',
                    px: 2,
                    py: 1,
                    boxShadow: 5,
                }}
            >
                <Typography
                    variant="h7"
                    style={{ color: 'black', display: 'block', fontSize: isMobile ? '1.5rem' : '1rem' }}
                    sx={{ mt: 1, fontWeight: 'bold', textAlign: 'left' }}
                >
                    {nome}
                </Typography>
                <Typography
                    variant="subtitle1"
                    style={{ display: 'block', fontSize: isMobile ? '1.4rem' : '0.875rem' }}
                    sx={{ mt: 1, textAlign: 'left' }}
                >
                    {funcao}
                </Typography>
                <Typography
                    style={{ display: 'block', fontSize: isMobile ? '1.4rem' : '0.875rem' }}
                    sx={{
                        mt: 1,
                        color: 'gray',
                        textAlign: 'left',
                    }}
                >
                    {descricao}
                </Typography>
                {tecnologias && 
                    <>
                        <Typography
                            variant="subtitle1"
                            style={{ display: 'block', fontSize: isMobile ? '1.2rem' : '0.875rem' }}
                            sx={{ fontWeight: 'bold', textAlign: 'left' }}
                        >
                            Tecnologias:
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                                gap: 2,
                                mt: 1,
                            }}
                        >
                            {tecnologias.map((tech, index) => (
                                <img
                                    key={index}
                                    src={tech}
                                    alt={`tech_${index}`}
                                    style={{
                                        height: isMobile ? '50px' : '30px',
                                        objectFit: 'contain',
                                    }}
                                />
                            ))}
                        </Box>
                    </>
                }
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: 2,
                    }}
                >
                    {redesSociais.instagram && (
                        <a
                            href={redesSociais.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`bg-[#e5cfc0] flex items-center justify-center rounded-full ${isMobile ? 'w-[50px] h-[50px]' : 'w-[40px] h-[40px]'} cursor-pointer`}
                        >
                            <IoLogoInstagram size={isMobile ? 30 : 20} />
                        </a>
                    )}
                    {redesSociais.linkedin && (
                        <a
                            href={redesSociais.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`bg-[#e5cfc0] flex items-center justify-center rounded-full ${isMobile ? 'w-[50px] h-[50px]' : 'w-[40px] h-[40px]'} cursor-pointer`}
                        >
                            <FiLinkedin size={isMobile ? 30 : 20} />
                        </a>
                    )}
                    {redesSociais.github && (
                        <a
                            href={redesSociais.github}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`bg-[#e5cfc0] flex items-center justify-center rounded-full ${isMobile ? 'w-[50px] h-[50px]' : 'w-[40px] h-[40px]'} cursor-pointer`}
                        >
                            <FiGithub size={isMobile ? 30 : 20} />
                        </a>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default PerfilMembro