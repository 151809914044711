import { googleLogout } from "@react-oauth/google"
import { useState, useEffect } from "react"
import { ImExit } from "react-icons/im"
import { IoPersonCircleSharp } from "react-icons/io5"
import { IoPersonAddOutline } from 'react-icons/io5'
import { useNavigate } from "react-router-dom"
import { motion, AnimatePresence } from "framer-motion"
import { Box, Typography, useTheme } from "@mui/material"
import { isBrowser } from "react-device-detect"

const Header = ({ perfil }) => {
    const dados = JSON.parse(localStorage.getItem("user"))
    const nivelPerm = localStorage.getItem('perm')
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()
    const theme = useTheme()
    const foto = dados?.picture
    const logOut = () => {
        googleLogout()
        localStorage.clear()
        navigate("/")
    }

    // Fecha o modal ao clicar fora dele
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (modal && !e.target.closest("#modal-content") && !e.target.closest("#profile")) {
                setModal(false)
            }
        }

        document.addEventListener("click", handleClickOutside)
        return () => document.removeEventListener("click", handleClickOutside)
    }, [modal])

    return (
        <header className="relative">
            <AnimatePresence>
                {modal && (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9, y: -20 }}
                        animate={{ opacity: 1, scale: 1, y: 0 }}
                        exit={{ opacity: 0, scale: 0.9, y: -20 }}
                        transition={{ duration: 0.2 }}
                        className={`absolute flex flex-col w-48 bg-gray-100 shadow-lg rounded-lg end-0 ${isBrowser ? 'mt-[68px]' : 'mt-[100px]'} z-30`}
                        id="modal-content"
                    >
                        <div className="flex flex-col gap-2 p-4">
                            {nivelPerm !== 'colaborador' && nivelPerm !== 'associado' && nivelPerm !== 'curador' &&
                                <div onClick={() => { navigate('/admin/contas') }} className="flex items-center gap-1 cursor-pointer hover:bg-gray-200 rounded">
                                    <div className="w-10">
                                        <button title='Gerenciar Usuários' className='botao_3 flex items-center justify-center text-base'>
                                            <IoPersonAddOutline color={theme.palette.custom.cor_texto} size={16} />
                                        </button>
                                    </div>
                                    <Typography sx={{ fontSize: '12px', color: theme.palette.custom.cor_0, fontWeight: 'bold' }}>Gerenciar Usuários</Typography>
                                </div>
                            }
                            <button onClick={logOut} className="flex items-center gap-2 py-2 px-4 text-left text-red-600 hover:bg-gray-200 rounded">
                                <ImExit size={16} />
                                Sair
                            </button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <div style={{ backgroundColor: "white" }} className={`flex flex-row items-center justify-between w-full ${perfil ? '' : 'px-[85px]'} ${isBrowser ? 'h-[72px]' : 'h-32'}`}>
                <Box sx={{cursor: 'pointer'}}  onClick={() => navigate('/equipe')}>
                    <Typography  style={{ fontSize: '25px', fontWeight: 'bold', color: theme.palette.custom.cor_0, marginLeft: "20px", marginTop: "10px", fontFamily: '"Pinyon Script", serif', display: 'inline' }}>
                        Transcritor
                    </Typography>   
                    <Typography variant={isBrowser ? 'h5' : 'h2'} style={{ marginLeft: '-4px', color: theme.palette.custom.cor_0, marginLeft: "5px", marginTop: "10px", fontFamily: '"Anta", serif', display: 'inline' }}>
                        IA
                    </Typography>
                </Box>
                {perfil ? (
                    <div
                        id="profile"
                        onClick={() => setModal((prev) => !prev)}
                        className="flex items-center h-full mr-5 cursor-pointer"
                    >
                        <Typography variant={isBrowser ? '' : 'h5'}sx={{ fontSize: '14px', color: theme.palette.custom.cor_0, fontWeight: 'bold'}}>{dados?.name}</Typography>
                        {dados?.picture ? (
                            <img
                                className={`${isBrowser ? 'h-16' : 'h-16'} rounded-full ml-5`}
                                alt="foto_perfil"
                                src={foto}
                                referrerPolicy="no-referrer"
                            />
                        ) : (
                            <IoPersonCircleSharp size={isBrowser ? 55 : 90} color={theme.palette.custom.cor_0} className="ml-5" />
                        )}
                    </div>
                ):(
                    <Box
                        id="equpe"
                        onClick={() => setModal((prev) => !prev)}
                        className="flex items-center h-full mr-5 cursor-pointer"
                    >
                        <Typography variant={isBrowser ? '' : 'h5'}  style={{ fontSize: '14px', color: theme.palette.custom.cor_0, marginLeft: "20px" }} className="font-semibold"></Typography>
                    </Box>
                )}
            </div>
        </header>
    )
}

export default Header