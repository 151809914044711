import { IoSearchOutline } from "react-icons/io5"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { isBrowser } from "react-device-detect"

const Busca = ({ placeholder = "Pesquise...", livro }) => {
    const [inputbusca, setInputbusca] = useState("")
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()
        if (inputbusca.trim()) {
            if(livro){
                navigate(`/busca?search=${inputbusca.trim()}&id=${livro}`)
            }else{
                navigate(`/busca?search=${inputbusca.trim()}`)
            }
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e)
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="relative flex items-center">
                    <IoSearchOutline 
                        onClick={handleSubmit} 
                        size={isBrowser ? 15 : 30} 
                        color="black" 
                        className="absolute ml-1 cursor-pointer"
                    />
                    <input
                        placeholder={placeholder}
                        value={inputbusca}
                        onChange={(e) => setInputbusca(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className={`text-black placeholder-black ${
                            isBrowser 
                                ? 'pl-6 text-sm w-[35vw]' 
                                : 'py-2 pl-8 text-lg w-full truncate'
                        } border-2 border-white rounded-xl`}
                    />
                </div>
            </form>
        </div>
    )
}

export default Busca