import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, Typography, Box, Divider, useTheme } from '@mui/material'
import { isBrowser, isMobile } from 'react-device-detect'

const MenuT = ({ rotas, botoes, alt_busca }) => {
    const theme = useTheme()
    return (
        <Box position="static" sx={{display: 'flex', alignItems: 'center', height: isBrowser ? '50px' : '100px', backgroundColor: theme.palette.custom.cor_menu_t, paddingX: 2, paddingY: isBrowser? 0 : 2  }}>
            <Toolbar sx={{ display: 'flex', width: '100%',  justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {rotas.map((rota, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: theme.palette.custom.cor_texto }} />}
                            {rota.link ? (
                                <Typography component={Link} to={rota.link} sx={{ textSize: '14px', color: theme.palette.custom.cor_texto, textDecoration: 'none' }}>
                                    {rota.nome}
                                </Typography>
                            ) : (
                                <Typography sx={{ textSize: isBrowser ? '14px' : '30px', color: theme.palette.custom.cor_texto, cursor: 'default' }}>
                                    {rota.nome}
                                </Typography>
                            )}
                        </React.Fragment>
                    ))}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {botoes.map((botao, index) => (
                        <Box key={index} color="inherit">
                            {botao}
                        </Box>
                    ))}
                </Box>
            </Toolbar>
            {(alt_busca && isMobile) && (
                <Box sx={{ marginTop: 2 }}>
                    {alt_busca}
                </Box>
            )}
        </Box>
    )
}

export default MenuT