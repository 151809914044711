import React, { useState, useEffect } from 'react'
import { FaCircle } from 'react-icons/fa'
import { FaCircleCheck } from 'react-icons/fa6'
import { Box, Typography, IconButton, TextField, useTheme } from '@mui/material'

const Linha = ({ view, linha, indexLinha, indexRegiao, validarLinha, handleFocarLinha, getDados }) => {
    const theme = useTheme()
    const token = localStorage.getItem('key')
    const [editando, setEditando] = useState(false)
    const [texto, setTexto] = useState(linha.transcricao)
console.log(view)
    useEffect(() => {
        setTexto(linha.transcricao)
    }, [linha.transcricao])

    const backgroundColor = linha.focado
        ? theme.palette.custom.focada
        : linha.certificada
        ? theme.palette.custom.certificada
        : 'white' // cor padrão

    const certificarLinha = async (idLinha) => {
        try {
            await fetch(`https://transcritor-ia.com:8086/api/livro/${idLinha}/certificar-linha/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            })
            getDados()
        } catch (error) {
            console.error('Erro ao certificar a linha:', error)
        }
    }

    const retranscreverLinha = async (idLinha) => {
        try {
            await fetch(`https://transcritor-ia.com:8086/api/livro/${idLinha}/retranscrever/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            })
            getDados()
        } catch (error) {
            console.error('Erro ao retranscrever a linha:', error)
        }
    }

    const handleEditChange = async (e) => {
        const value = e.target.value
        setTexto(value)

        if(e.key === 'Enter'){
            try {
                await fetch(`https://transcritor-ia.com:8086/api/livro/${linha.id_linha}/editar-transcricao/`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        transcricao: value,
                    }),
                })
                getDados()
            } catch (error) {
                console.error('Erro ao editar a transcrição:', error)
            }
        }

    }

    return (
        <Box key={linha.id_linha + indexRegiao * 2} display="flex" mx={2} gap={2} alignItems="center">
            <Typography
                variant="h6"
                sx={{
                    color: 'black',
                    ml: indexLinha > 8 ? -0.8 : 0,
                    mr: indexLinha > 8 ? -.5 : 0,
                }}
            >
                {indexLinha + 1}
            </Typography>
            <Box
                key={indexLinha}
                sx={{ backgroundColor }}
                display="flex"
                px={2}
                width={'100%'}
                alignItems="center"
                justifyContent="center"
                mt={3}
                className="cursor-pointer rounded-lg"
                onClick={() => {
                    handleFocarLinha(linha.id_linha, true)
                    setEditando(true)
                    setTexto(linha.transcricao)
                }}
            >
                {editando ? (
                    <TextField
                        fullWidth
                        value={texto}
                        onChange={view ? null : handleEditChange}
                        onDoubleClick={() => validarLinha(linha, indexLinha, indexRegiao)}
                        onBlur={() => setEditando(false)}
                        autoFocus
                        variant="standard"
                        multiline
                    />
                ) : (
                    <Typography
                        onDoubleClick={() => validarLinha(linha, indexLinha, indexRegiao)}
                        className="w-full text-start"
                    >
                        {linha.transcricao || `Linha ${indexLinha + 1}`}
                    </Typography>
                )}
                {view === false && (
                    linha.processando ? (
                        <img className='h-6 w-6 bg-black rounded-full ml-2 mr-2 my-2' src='/imagens/magic_animation.webp'></img>
                    ) : linha.certificada ? (
                        <IconButton onClick={() => retranscreverLinha(linha.id_linha)} className="ml-2">
                            <FaCircleCheck />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => certificarLinha(linha.id_linha)} className="ml-2">
                            <FaCircle />
                        </IconButton>
                    )
                )}
            </Box>
        </Box>
    )
}

export default Linha